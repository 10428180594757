import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({fontSizes, breakpoints, palette, spacing}) => ({
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizes.large,
    width: '33%',
    marginRight: '5%',
    [breakpoints.down('sm')]: {
      width: '100%',
      marginTop: spacing(3.125),
      paddingRight: 0,
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  navigationItemBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  navigationItem: {
    marginTop: spacing(2.5),
    textDecoration: 'none',
    '-webkit-appearance': 'none !important',
    color: 'inherit',
    fontSize: fontSizes.medium,
    '&:hover': {
      color: palette.primary.main,
    },
  },
  navigationItemGrey: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.8em',
    fontFamily: 'CamptonBook',
    color: palette.text.grey,
    textDecoration: 'none',
    '-webkit-appearance': 'none !important',
    border: 'none',
    background: 'transparent',
    padding: 0,
    outline: 'none',
    [breakpoints.down('sm')]: {
      cursor: 'pointer',
      width: '100%',
    },
  },
  arrowRevert: {
    transform: 'rotate(0.5turn)',
  },
}))

export default useStyles
